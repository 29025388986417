import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { v4 as uuidv4 } from 'uuid';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';
import accountModule from '@/store/modules/accountModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { Debounce } from 'vue-debounce-decorator';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqFeedbackModule',
  store,
})
class TasqFeedbackModule extends VuexModule {
  	currentSignals: any = [];

	stepper = 1

	// Validate

	// Systems
	systemsSelected: string[] = []

	// Symptoms
	symptomsSelected: string[] = []

	// Causes
	causesSelected: string[] = []

	// Actions
	actionsSelected: string[] = []

	actionBuilderActions: any = []

	availableForms: any = []

	actionsSelectedInputs: any = []

	selectedActionDetails: any = {}

	selectedActionInputTab: any = '';

	showFeedbackMiniView = false;


	isFeedbackMiniViewHovering = false;

	@Mutation
	setSelectedActionInputTab(data) {
	//   console.log(data);
	  this.selectedActionInputTab = data;
	}


	@Mutation
	setShowFeedbackMiniView(data) {
	//   console.log(data);
	  this.showFeedbackMiniView = data;
	}



	@Mutation
	setIsFeedbackMiniViewHovering(data) {
	//   console.log(data);
	  this.isFeedbackMiniViewHovering = data;
	}

	@Mutation
	setSelectedActionDetails(action) {
	  // console.log(action)
	  this.selectedActionDetails = action;
	}

	@Mutation
	setStepper(step) {
	  this.stepper = step;
	}

	// New implementation

	selectedForms: any = [];

	selectedFormsFields: any = [];

	validationResponse: any = {
	  selectionAction: '',
	  selectionActionComment: '',

	  };

	@Mutation
	setAvailableForms(forms) {
	  if (forms && forms.length) {
	    this.actionBuilderActions = forms;
	  }
	}

	@Mutation
	setValidateResponseComment(comment) {
	  this.validationResponse.selectionActionComment = comment;
	}

	@Mutation
	pushSelectedForm(form) {
	 const index = this.selectedForms.findIndex((existingForm) => existingForm.id === form.id);
	 console.log(form.title);
	 console.log(index);
	  if (index < 0) {
	    this.selectedForms.push(form);
	  }
	  this.selectedForms.sort((a, b) => a.title.localeCompare(b.title));
	}

	@Mutation
	removeSelectedFrom(index) {
	  this.selectedForms.splice(index, 1);
	}

	@Mutation
	setValidateResponse(response) {
	  this.validationResponse = response;
	}

	@Mutation
	setSeletedForms(response) {
	  this.selectedForms = response;
	}


	@Mutation
	setSeletedFormsFields(response) {
	  this.selectedFormsFields = response;
	}




	@Mutation
	setResponseSelectionAction(data) {
	  this.validationResponse.selectionAction = data;
	}

	feedbackSteps = [
	  {
	    id: 1,
	    number: 1,
	    name: 'Validate',
	    active: true,
	  },
	  {
	    id: 2,
	    number: 2,
	    name: 'Action',
	    active: false,
	  },
	  {
	    id: 3,
	    number: 3,
	    name: 'Input',
	    active: false,
	  }, {
	    id: 4,
	    number: 4,
	    name: 'Final',
	    active: false,
	  },
	];

	innerCommentVal = ''

	@Mutation
	setFeedbackSteps(step) {
	  this.feedbackSteps[step - 1].active = true;
	}

	@Mutation
	resetFeedbackSteps() {
	  this.feedbackSteps = [
	    {
	      id: 1,
	      number: 1,
	      name: 'Validate',
	      active: false,
	    },
	    {
	      id: 2,
	      number: 2,
	      name: 'Action',
	      active: false,
	    },
	    {
	      id: 3,
	      number: 3,
	      name: 'Input',
	      active: false,
	    },
	    {
	      id: 4,
	      number: 3,
	      name: 'Final',
	      active: false,
	    },
	  ];
	}

	@Action
	resetAllData() {
	  this.setStepper(1);
	  this.resetFeedbackSteps();
	  this.setValidateResponse({ selectionAction: '', selectionActionComment: '' });
	  this.setSeletedForms([]);
	}


	@Action
	setupBatchResponsePage(shouldSkipStepperSet = false) {

		this.setFeedbackSteps(2);
	    this.setFeedbackSteps(1);
		this.setStepper(2);
	}

	@Action
	setupPage(shouldSkipStepperSet = false) {
	  this.resetAllData();

	  // console.log(tasqsListModule.activeTasq.validationResponse);
	  // console.log(tasqsListModule.activeTasq.formsResponse);
	  if (tasqsListModule.activeTasq && tasqsListModule.activeTasq.validationResponse && Object.keys(tasqsListModule.activeTasq.validationResponse).length) {
	    this.setValidateResponse(tasqsListModule.activeTasq.validationResponse);
	    this.setFeedbackSteps(2);
	    this.setFeedbackSteps(1);
	    if (!shouldSkipStepperSet) {
	      this.setStepper(2);
	    }
	  }

	  if (tasqsListModule.activeTasq && tasqsListModule.activeTasq.formsResponse && Object.keys(tasqsListModule.activeTasq.formsResponse).length) {
	    this.setSeletedForms(tasqsListModule.activeTasq.formsResponse);
	    this.setFeedbackSteps(3);
	    this.setFeedbackSteps(2);
	    this.setFeedbackSteps(1);
	    if (!shouldSkipStepperSet) {
	      this.setStepper(3);
	    }
	  }
	}

  @Mutation
	resetSignals(): void {
	  this.currentSignals = [];
	}

  @Mutation
  addSignal(signal): void {
    this.currentSignals.push(signal);
  }

  @Action
  async postComment({
    tasq,
    selection,
    reason = [],
    correctedStateChangeDate = '',
    comment = '',
    jobAction = [],
    setpointData = null,
  }) {
    try {
      const commentId = '';

      const responseData = {
        NodeID: tasq.wellName,
        ResponseID: uuidv4(),
        Payload: JSON.stringify(setpointData),
        UserResponse: {
          Selection: selection,
          SelectionDetails: {
            Reason: reason,
            // @ts-ignore
            ...jobAction && {
              Action: jobAction,
            },
          },
          ...correctedStateChangeDate && {
            CorrectedStateChangeDate: correctedStateChangeDate,
          },
        },
      };

      const {
        data: {
          post_job_comment: {
            comment_id: jobCommentId,
          },
        },
      } : any = await workflowApolloClient.mutate({
        mutation: postJobComment,
        variables: {
          input: {
            prediction_id: tasq.id,
            username: accountModule.user.email.toLowerCase(),
            comment,
            response_data: responseData,
            should_post_as_global_well_comment: true,
          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }
}

export default getModule(TasqFeedbackModule);
